@media screen and (max-width: 1100px) {
  .margin-top-head {
    margin-top: 8vh;
  }

  .logo-img {
    width: 110px;
    height: auto;
  }

  .type-text {
    font-size: 12px !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .home-navbar {
    padding: 0px;
  }

  .bg-video-wrap2 {
    margin-top: 6vh;
    height: 30vh;
  }

  .bg-video-wrap2 .overlay {
    height: 30vh;
  }

  .bg-video-wrap2 video {
    height: 30vh;
  }

  .bg-video-wrap2 .home-video-content {
    height: 30vh;
  }

  .banner-heading {
    font-size: 25px;
  }

  .home-video-content p {
    font-size: 15px;
  }

  .site-card {
    margin: 0 0px;
  }

  .site-info-img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  .home-banner-bottom-container {
    margin-top: -20px;
  }

  /*modal start*/
  .Modal-View {
    width: 75%;
  }

  .modal-content {
    width: 100% !important;
  }

  .dropdown-menu .nav-item .nav-link {
    color: #000000;
    text-decoration: none;
    padding: 10px 20px;
  }

  .navbar-nav .dropdown-menu {
    background-color: #ffffff;
  }

  .dropdown-menu .nav-item .nav-link:hover,
  .dropdown-menu .nav-item .nav-link.active {
    color: #990000;
    font-weight: 600;
    text-decoration: none;
    padding: 10px 20px;
  }

  .dropdown-menu .nav-item .nav-link.active>.navbar-light .navbar-nav .nav-link {
    color: #990000;
  }

  .center-aligned-head {
    text-align: center;
  }

  .data-play-head-bg h4 {
    font-size: 16px;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #c5c5c5;
  }

  .join-now-bg {
    height: 50vh;
  }

  .academycard-img .acd-img-icon {
    width: 70px;
    height: 70px;
  }

  .academycard-img {
    top: -45px;
  }

  .company-vibe {
    height: 45vh;
  }

  .bg-grey-card {
    margin-top: 20px;
  }

  .business-card {
    padding: 22px;
    margin: 10px;
  }
}

@media screen and (max-width: 650px) {
  .academycard-img {
    top: -50px;
  }

  .academycard-img .acd-img-icon {
    width: 100px;
    height: 100px;
  }

  .logo-img {
    width: 150px;
    height: auto;
  }

  .type-text {
    font-size: 16px !important;
  }

  .bg-video-wrap2 {
    margin-top: 8vh;
    height: 33vh;
  }

  .bg-video-wrap2 .overlay {
    height: 33vh;
  }

  .bg-video-wrap2 .home-video-content {
    height: 33vh;
  }

  .banner-heading {
    font-size: 20px !important;
    margin-bottom: 10px;
  }

  .home-video-content p {
    font-size: 9px;
  }

  .home-video-content .contact-btn {
    margin-top: 10px !important;
  }

  .contact-btn {
    padding: 10px 16px;
  }

  .section-heading {
    font-size: 25px !important;
    margin-bottom: 10px;
  }

  .our-clients-inner-sec {
    padding-top: 35px;
    padding-bottom: 25px;
  }

  .margin-top-head {
    margin-top: 0vh;
  }

  .icon-playpause {
    font-size: 20px;
    width: 40px;
    height: 40px;
  }

  .posi-absolute-btn {
    bottom: 36%;
    left: 43%;
  }

  .agency-content-height {
    height: auto;
  }

  .agency-card {
    padding: 16px;
    margin: 0px;
  }

  .step-icon {
    width: 60px;
    height: 60px;
  }

  /*modal start*/
  .Modal-View {
    width: 100% !important;
  }

  .modal-content {
    width: 90% !important;
  }

  .bg-video-wrap2 video {
    display: none;
  }

  .home-banner-bottom-container {
    position: relative;
    margin-top: 0;
  }

  .bg-banner-bottom-content img {
    padding: 20px;
  }

  .site-info {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .site-info-img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .site-card {
    padding: 10px;
    margin: 0;
    margin-bottom: 5px;
  }

  .every-do-img {
    display: none;
  }

  .peme-4 {
    padding-right: 0;
  }

  .step-card-2 {
    margin-top: 0;
  }

  .step-card-3 {
    margin-top: 0;
  }

  .just-center {
    justify-content: center !important;
  }

  .casestudy-para {
    height: auto !important;
    font-size: 14px;
  }

  .relative-team {
    position: relative;
    right: 0;
    width: 100%;
    bottom: 50px;
  }

  .relative-founder {
    position: relative;
    bottom: 0;
  }

  .founder-red-bg {
    width: 100%;
  }

  .case-study .owl-carousel .owl-nav button.owl-prev {
    right: auto;
    left: 100px;
  }

  .case-study .owl-carousel .owl-nav button.owl-prev,
  .case-study .owl-carousel .owl-nav button.owl-next {
    right: 100px;
    top: auto;
  }

  .dropdown-menu .nav-item .nav-link {
    color: #000000;
    text-decoration: none;
    padding: 10px 20px;
  }

  .navbar-nav .dropdown-menu {
    background-color: #ffffff;
  }

  .dropdown-menu .nav-item .nav-link:hover,
  .dropdown-menu .nav-item .nav-link.active {
    color: #990000;
    font-weight: 600;
    text-decoration: none;
    padding: 10px 20px;
  }

  .dropdown-menu .nav-item .nav-link.active>.navbar-light .navbar-nav .nav-link {
    color: #990000;
  }

  .business-card {
    padding: 20px;
    margin: 10px;
  }

  .business-card-2 {
    margin: 10px;
  }

  .inner-container-contact {
    padding: 15px;
  }

  .company-vibe {
    height: auto;
  }

  .join-now-bg {
    height: 54vh;
  }

  .join-now-bg h6 {
    font-size: 12px;
  }

  .whocanchoose-card {
    padding: 15px;
    height: auto;
    padding-top: 50px !important;
    margin: 80px 0px 10px;
  }

  .bg-grey-card {
    margin-top: 25px;
  }

  .work-img .content-wwh {
    padding: 0px;
  }

  .case-para-home {
    height: auto;
  }

  .agency-card {
    height: auto;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #c5c5c5;
  }

  .bold-letter-founder {
    font-size: 17px !important;
  }

  .para-white {
    font-size: 14px !important;
  }

  .testimonial-section .owl-carousel .owl-nav button.owl-prev,
  .testimonial-section .owl-carousel .owl-nav button.owl-next {
    bottom: 0px;
    left: auto;
    right: 90px;
  }

  .testimonial-section .owl-carousel .owl-nav button.owl-prev {
    left: 90px;
  }

  .growing-fast-font {
    font-size: 16px !important;
  }

  .center-aligned-head {
    text-align: center !important;
  }

  .flex-direct-mob {
    flex-direction: column-reverse;
  }

  .font-small-mob {
    font-size: 16px !important;
  }

  .font-small-approach-mob {
    font-size: 14px !important;
  }

  img.award-img {
    height: auto;
  }

  /* modal end */
  .business-card h5 {
    font-size: 16px !important;
  }

  .img-top-team {
    margin-top: -43px;
  }

  .fac-img {
    margin-top: -83px;
  }

  .team-para-he {
    height: auto;
  }
  .affiliate-redirect-btn {
    color: #fff;
    background-color: #a01d26;
    border-color: #a01d26;
    border-radius: 6px;
    font-size: 18px;
    padding: 12px 70px;
    font-family: "OpenSans-Bold";
    margin: 0px 0px 90px !important;
  }
}