@font-face {
  font-family: "OpenSans-Regular";
  src: url("../assets/fonts/static/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/static/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("../assets/fonts/static/OpenSans-ExtraBold.ttf");
}

* {
  font-family: "OpenSans-Regular";
}

/* footer css start */

.bg-footer {
  background-color: #000000;
  padding: 50px 0 0px;
}

.bg-footer a:hover {
  color: #d90917;
}

.footer-heading {
  letter-spacing: 2px;
}

.logo-footer {
  width: 170px;
  height: auto;
}

.footer-link a {
  color: #fff;
  line-height: 30px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.5s;
}

/* ul.footer-link {
  padding-left: 20px;
} */
.footer-link a:hover {
  color: #d60d0d;
}

.footer-link li {
  color: #fff;
}

.contact-info {
  color: #ffffff;
  font-size: 14px;
}

.footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  transition: border-radius 1s;
}

.footer-social-icon:hover {
  border-radius: 3px;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.youtube {
  background-color: #b9250b;
  color: #ffffff;
}

.google {
  background-color: #eb5702;
  color: #ffffff;
}

.insta {
  background-color: #ca0065;
  color: #ffffff;
}

.footer-alt {
  color: #ffffff;
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
  font-family: "OpenSans-Bold";
}

.footer-heading:after {
  content: "";
  width: 25px;
  border-bottom: 1px solid #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #ffffff;
}

/* .footer-input {
  color: #fff;
  background-color: #fff0;
  border: 1px solid transparent;
  border-bottom: 1px solid #737272;
  border-radius: 0px;
  padding: 0.375rem 0rem;
} */

.footer-input {
  color: #fff;
  background-color: #fff0;
  border: 1px solid #ffffff;
  border-radius: 30px;
  height: 45px;
  font-size: 15px;
  padding: 0.375rem 100px 0.375rem 0.95rem;
}

.footer-input:focus {
  color: #fff;
  background-color: #fff0;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
  border: 2px solid #e0e0e0;
}

.footer-input::placeholder {
  color: #737272;
  opacity: 1;
}

.footer-input::-ms-input-placeholder {
  color: #737272;
}

.btn-light {
  color: #fff;
  background-color: #a01d26;
  border-color: #a01d26;
  border-radius: 0px;
  font-size: 15px;
  padding: 8px 30px;
  width: 100%;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  font-weight: 700;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.footer-btn {
  position: absolute;
  padding: 0px 15px !important;
  height: 43px !important;
  margin-top: 0px !important;
  top: 1px;
  border: 1px solid #fff;
  border-radius: 30px !important;
  right: 1px;
  width: auto !important;
}

/* footer css end */

/* navbar start */

@media all and (min-width: 992px) {
  .dropdown-menu {
    width: 13rem;
  }

  .mega-submenu {
    left: 100%;
    top: 0;
    min-width: 25rem;
  }

  .ktm-mega-menu {
    position: static;
  }

  .mega-menu {
    left: 0;
    right: 0;
    width: 100%;
  }

  .dropdown-menu li {
    position: relative;
  }

  .dropdown-menu .submenu {
    display: none;
    left: 100%;
    top: 0;
  }

  .dropdown-menu > li:hover > .submenu,
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
}

.home-navbar .navbar-light .navbar-nav .nav-link.active,
.home-navbar .navbar-light .navbar-nav .nav-link:hover {
  color: #a01d26 !important;
  font-family: "OpenSans-Bold";
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0);
  font-weight: 500;
  font-family: "OpenSans-Bold";
}

.bg-light-nav-top {
  background-color: #ffffff !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.logo-img {
  width: 200px;
  height: auto;
}

.home-navbar {
  padding: 15px;
}

.contact-btn {
  color: #fff;
  background-color: #a01d26;
  border-color: #a01d26;
  border-radius: 6px;
  padding: 10px 60px;
  font-family: "OpenSans-Bold";
}

.nav-item .contact-btn {
  color: #fff;
  background-color: #a01d26;
  border-color: #a01d26;
  border-radius: 6px;
  padding: 10px 25px;
  font-family: "OpenSans-Bold";
}

.contact-btn:focus + .contact-btn,
.contact-btn:focus,
.contact-btn:hover,
.contact-btn.active,
.contact-btn:active {
  color: #a01d26;
  background-color: #ffffff;
  border-color: #a01d26;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.active .contact-btn {
  color: #a01d26;
  background-color: #ffffff;
  border-color: #a01d26;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 1%);
}

.navbar-nav .dropdown-menu {
  background-color: #ffffff;
}

.tabs .tab {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
  border-bottom: 1px solid #000000;
  margin-bottom: 20px;
  font-family: "OpenSans-Bold";
}

.tabs .tab.active {
  border-bottom: 1px solid #a01d26;
  color: #ffffff;
  background-color: #a01d26;
}

.tabs .tab span {
  font-family: "OpenSans-Bold";
}

.tabs .tab:hover {
  border-bottom: 1px solid #a01d26;
  color: #ffffff;
  background-color: #a01d26;
  font-weight: 500;
}

.mega-menu-inner {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padd-left {
  padding-left: 50px;
}

.cycle-style {
  width: 25px;
  margin-right: 10px;
  height: fit-content;
}

.padd-left p {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.bg-food-agr {
  padding: 5px;
  cursor: pointer;
}

.bg-food-agr.active {
  background-color: #3ec5fa;
}

.inner-icon {
  width: 30px;
  height: 30px;
}

.bg-star-icon {
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 8px;
  margin: 3px;
  margin-right: 8px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* navbar end */

.banner-heading {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
  font-family: "OpenSans-Bold";
}

.bg-video-wrap2 video {
  width: 100%;
  height: 89vh;
  z-index: 1;
}

.bg-video-wrap2 {
  position: relative;
  background-image: url("../assets/images/si-banner.webp");
  height: 89vh;
  margin-top: 10vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
}

.bg-video-wrap2 .overlay {
  width: 100%;
  height: 89vh;
  position: absolute;
  top: 0;
  left: 0;
  background-size: auto;
  background-image: linear-gradient(38deg, black, transparent);
  z-index: 2;
}

.bg-video-wrap2 .home-video-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Typewriter__wrapper {
  font-family: "OpenSans-Bold";
}

.about-sub-heading {
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #000 !important;
  cursor: pointer;
  position: relative;
  font-size: 16px !important;
  font-family: "OpenSans-Bold";
  text-decoration: none;
}

.about-sub-heading::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 4px;
  border-radius: 4px;
  background-color: #a01d26;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.about-sub-heading:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  color: #a01d26;
}

.about-sub-heading:hover {
  font-family: "OpenSans-Bold";
  font-size: 18px !important;
  color: #a01d26 !important;
  transform: scaleX(1);
  transition: transform 0.3s ease-in-out;
}

.service-sub-head {
  font-size: 28px;
  font-family: "OpenSans-Bold";
  color: #fff;
}

.text-para-nav {
  color: #000000 !important;
}

.getstarted-btn {
  color: #fff;
  background-color: #a01d2600;
  border-color: #646464;
  border-radius: 6px;
  padding: 10px 25px;
  font-weight: 500;
}

.getstarted-btn:focus + .getstarted-btn,
.getstarted-btn:focus,
.getstarted-btn:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #646464;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.bg-banner-bottom-content {
  background-color: #fff;
  border-radius: 100px;
}

.home-content {
  position: relative;
}

.home-banner-bottom-container {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: -40px;
  z-index: 10;
}

.site-info-img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.site-info {
  padding-top: 120px;
  padding-bottom: 50px;
}

.site-value {
  font-family: "OpenSans-ExtraBold";
}

.site-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0 40px;
  margin-bottom: 20px;
}

.section-heading {
  font-family: "OpenSans-Bold";
  font-size: 40px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.color-sec-head {
  color: #a01d26;
  font-family: "OpenSans-Bold";
}

.section-gap {
  margin-bottom: 50px;
}

.para-color {
  color: #646464;
  font-size: 17px;
}

.para-white {
  color: #fff;
  font-size: 14px;
}

.heading-white {
  font-family: "OpenSans-ExtraBold";
  color: #fff;
}

.service-red-bg {
  background-color: #a01d26;
  padding-top: 70px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-radius: 20px;
  margin: 25px;
}

.btn-white {
  color: #a01d26;
  background-color: #ffffff;
  border-color: #a01d26;
  border-radius: 6px;
  padding: 10px 25px;
  font-family: "OpenSans-Bold";
}

.btn-white:focus + .btn-white,
.btn-white:focus,
.btn-white:hover {
  color: #ffffff;
  background-color: #a01d26;
  border-color: #ffffff;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.my-option-card {
  position: relative;
  margin-top: 70px;
}

.opt-img {
  width: 130px;
  height: 130px;
  position: absolute;
  top: -65px;
  left: 0px;
}

.height-para {
  height: 100px;
}

.our-clients {
  background-color: #121212;
}

.client-img {
  width: 100%;
  height: auto;
}

.our-clients-inner-sec {
  padding-top: 70px;
  padding-bottom: 80px;
}

.founder-para {
  color: #646464;
  text-align: justify;
}

.data-play-head-bg {
  background-color: #a01d26;
}

.dp-img {
  width: 150px;
  height: 150px;
}

.data-play-container {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.award-container {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

img.award-img {
  width: 100%;
  height: 350px;
}

.award-content {
  background-color: #221f1f;
  padding: 10px;
}

.dimg-container {
  position: relative;
}

.dimg {
  width: 90%;
  height: auto;
  margin-left: 10%;
}

.founder-red-bg {
  background-color: #a01d26;
  padding: 15px;
  padding-top: 30px;
  width: 100%;
  position: relative;
  border-radius: 15px;
}

.bold-letter-founder {
  font-family: "OpenSans-Bold";
}

.founder-lh {
  line-height: 30px;
}

.relative-founder {
  position: absolute;
  bottom: -40px;
}

.relative-team {
  position: absolute;
  bottom: -40px;
  right: 0;
  width: 70%;
}

.icon-quote {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -25px;
  right: -10px;
}

.teamimg {
  width: 90%;
  height: auto;
  margin-right: 10%;
}

.team-white-bg {
  background-color: #ffffff;
  padding: 15px;
  padding-top: 30px;
  width: 100%;
  position: relative;
  border-radius: 15px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.grow-img {
  width: 25px;
  height: 25px;
  margin-right: 2px;
}

.map-img {
  width: 100%;
  height: auto;
  margin: auto;
}

.team-para {
  color: #646464;
  font-size: 14px;
}

.font-extrabold {
  font-family: "OpenSans-ExtraBold";
}

.font-bold {
  font-family: "OpenSans-Bold";
}

.case-study {
  background: linear-gradient(
    100.15deg,
    rgba(220, 220, 220, 0.5) 0%,
    rgba(248, 250, 255, 0) 101.04%
  );
}

.case-study-card {
  padding: 15px;
  margin: 25px 0px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.redirect-casestudy {
  color: #a01d26;
  font-size: 14px;
  text-decoration: underline;
  font-family: "OpenSans-Bold";
}

.casestudy-para {
  height: 130px;
  font-size: 14px;
}

.casestudy-img {
  display: block;
  width: 170px !important;
  height: 65px;
  object-fit: contain;
  object-position: left;
}

.case-study .owl-carousel .owl-nav.disabled {
  display: block !important;
}

.case-study .owl-carousel .owl-nav button.owl-prev,
.case-study .owl-carousel .owl-nav button.owl-next {
  background: none;
  color: #a01d26;
  border: none;
  padding: 0 !important;
  font: message-box;
  font-size: 30px !important;
  position: absolute;
  top: -90px;
  right: 50px;
  width: 40px;
  height: 40px;
  border: 1px solid #a01d26 !important;
  border-radius: 50px !important;
}

.case-study .owl-carousel .owl-nav button.owl-prev {
  right: 100px;
}

.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
  position: absolute !important;
  top: -5px !important;
  left: 14px !important;
  color: #a01d26;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #a01d26 !important;
  color: #fff !important;
  text-decoration: none;
}

.owl-theme .owl-nav [class*="owl-"]:hover > span {
  color: #fff !important;
}

.owl-carousel .owl-nav button.owl-prev span:hover,
.owl-carousel .owl-nav button.owl-next span:hover {
  color: #ffffff;
}

.accordion-item {
  margin-bottom: 25px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.accordion-button {
  border: 1px solid #9e9e9e00;
  border-radius: 5px;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #9b0b15;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion-button:focus {
  border-color: #86b7fe00;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.testimonial-section .owl-carousel .owl-nav button.owl-prev,
.testimonial-section .owl-carousel .owl-nav button.owl-next {
  background: none;
  color: #a01d26;
  border: none;
  padding: 0 !important;
  font: message-box;
  font-size: 30px !important;
  position: absolute;
  bottom: 30px;
  left: -80px;
  width: 40px;
  height: 40px;
  border: 1px solid #a01d26 !important;
  border-radius: 50px !important;
}

.testimonial-section .owl-carousel .owl-nav button.owl-prev {
  left: -130px;
}

.bi-quote {
  width: 40px !important;
  height: 40px;
}

.testi-img {
  display: block;
  width: 65px !important;
  height: 65px;
  object-fit: contain;
  object-position: left;
  border-radius: 100px;
}

.testi-para {
  font-size: 12px;
}

.border-color-red {
  color: #ec5c2e;
}

.step-icon {
  width: 70px;
  height: 70px;
}

.peme-4 {
  padding-right: 50px;
}

.step-card-2 {
  margin-top: -50%;
}

.step-card-3 {
  margin-top: -100%;
}

.margin-top-head {
  margin-top: 12vh;
}

.contact-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.con-img {
  width: 25px;
  height: 25px;
}

.inner-container-contact {
  padding: 40px;
}

.contact-container .form-control:focus {
  border-color: #a01d26;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.contact-container .form-control {
  padding: 0.75rem;
}

.contact-container .form-check-input:checked {
  background-color: #a01d26;
  border-color: #a01d26;
}

.contact-container .form-check-input:focus {
  border-color: #a01d26;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #a01d2600;
}

.iframe-contact {
  width: 100%;
  height: 600px;
}

.casestudy-banner {
  background-image: url("../assets/images/casestudy-banner.webp");
}

.advisory-banner {
  background-image: url("../assets/images/advisory-banner.webp");
}

.asseen-border {
  color: #2e272d;
  margin-top: 50px;
  margin-bottom: 50px;
}

.asseen-border:not([size]) {
  height: 3px;
}

.asseenon-content {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.business-card {
  padding: 50px;
  border-radius: 10px;
  margin: 30px;
  border-left: 7px solid #a01d26;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.res-font {
  font-size: 15px;
}

.number-design {
  width: 80px;
  height: 80px;
}

.red-half-line {
  color: #a01d26;
  opacity: 1;
  width: 50px;
  margin: 10px 0;
}

.red-half-line:not([size]) {
  height: 4px;
}

.business-card-2 {
  padding: 10px;
  margin: 30px;
}

.approch-section {
  background: linear-gradient(
    261.07deg,
    rgba(160, 29, 38, 0.2) -22.16%,
    rgba(160, 29, 38, 0) 97.22%
  );
}

.hr-lines:before {
  content: " ";
  display: block;
  height: 2px;
  width: 55px;
  position: absolute;
  top: 50%;
  left: 17px;
  background: #af0000;
  z-index: 200000;
}

.hr-lines:after {
  content: " ";
  display: block;
  height: 2px;
  width: 55px;
  position: absolute;
  top: 50%;
  right: 17px;
  background: #af0000;
  z-index: 200000;
}

.contact-banner {
  background-image: url("../assets/images/contact-banner.webp");
  height: 80vh;
}

.contact-banner .home-video-content {
  height: 80vh;
}

.workwithus-banner {
  background-image: url("../assets/images/workwithus-banner.webp");
}

.toursworkwithus-banner {
  background-image: url("../assets/images/toursworkshop-banner.webp");
}

.boxshadow-wwh {
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.content-wwh {
  padding: 20px;
}

.content-wwh h5 {
  line-height: 1.7;
}

.company-vibe {
  background-image: url("../assets/images/work-bg.webp");
  position: relative;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.academy-scope-img {
  background-image: url("../assets/images/academy-scope-bg.webp");
}

.tick-img {
  width: 25px;
  height: auto;
  margin-right: 18px;
  margin-top: 5px;
}

.number-wwu {
  font-size: 45px;
  color: #fff;
}

.why-join-us .owl-carousel .owl-dots.disabled,
.check-out-page .owl-carousel .owl-dots.disabled {
  display: block;
}

.work-img {
  background-color: #f4f4f4;
}

.agency-banner {
  background-image: url("../assets/images/agency-banner.webp");
}

.agency-video {
  border-radius: 30px;
}

.blogs-banner {
  background-image: url("../assets/images/blog-banner.webp");
}

.blog-details-banner {
  background-image: url("../assets/images/blog-details-bg.webp");
  height: 60vh;
}

.blog-details-banner .home-video-content {
  height: 60vh;
}

.posi-absolute-btn {
  position: absolute;
  bottom: 45%;
  left: 47%;
}

.icon-playpause {
  background-color: #fff;
  font-size: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agency-card {
  padding: 15px;
  border-radius: 10px;
  margin: 20px;
  height: 265px;
  text-align: center;
  border: 1px solid #267306;
  background-color: #fff;
}

.step-icon-2 {
  width: 50px;
  height: 50px;
}

.agency-content-height {
  /* height: 75px; */
  text-align: start;
  margin-bottom: 10px;
}

.border-red-color {
  border-left: 5px solid #a01d26 !important;
}

.about-banner {
  background-image: url("../assets/images/about-banner.webp");
}

/* modal start */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  height: 100vh;
  /* overflow-x: hidden; */
  padding-top: 0%;
  /* overflow-y: auto; */
}

body.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.modal-open {
  overflow: hidden;
}

.modal-content {
  min-height: 200px;
  background-color: #ffffff;
  border-radius: 8px;
  /* padding: 25px; */
}

.modalHeader {
  background: linear-gradient(180deg, #df7239 0%, #e19937 100%);
  color: #ffffff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.succ-failure {
  background-color: #00abad;
  color: #ffffff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.Modal-View {
  width: 75% !important;
  /* height: 700px; */
}

.book-now-modal-width .Modal-View {
  width: 100% !important;
  /* height: 700px; */
}

.modal-footer {
  background-color: #f2f2f2;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.modal-scroll-content {
  height: 56vh;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-scroll-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.modal-content .close-icon {
  position: absolute;
  right: -15px;
  top: -15px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid rgb(255, 255, 255);
  padding: 5px 10px;
  cursor: pointer;
  z-index: 100;
}

.modal-content .close-icon:hover {
  border: 2px solid #000;
}

.academy-banner {
  background-image: url("../assets/images/academy-banner.webp");
  height: 87vh;
}

.academy-banner .overlay {
  height: 87vh;
}

.academy-banner .home-video-content {
  height: 87vh;
}

.join-now-bg {
  background-image: url("../assets/images/join-now-bg.webp");
  position: relative;
  height: 82vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.x-small-para {
  font-size: 12px;
  height: 45px;
}

.fb-insta-icon {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.academycard-img {
  position: absolute;
  text-align: center;
  top: -50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.academycard-img .acd-img-icon {
  width: 100px;
  height: 100px;
}

.height-modal {
  height: 60vh;
  overflow-y: scroll;
}

.whocanchoose-card {
  padding: 15px;
  border-radius: 40px;
  border: 1px solid #000;
  margin-bottom: 15px;
  height: 80%;
  background-color: #fff;
  margin: 60px 0px 15px;
}

.fac-img {
  margin-top: -65px;
}

.academy-leader .case-study-card {
  margin: 30px 0px;
}

.bg-grey-card {
  background-size: auto;
  background-image: linear-gradient(182deg, #c9c7c7, #fff);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  width: 100%;
  height: auto;
  padding: 35px;
  border-radius: 15px;
  position: relative;
  margin-top: -25%;
  z-index: 50;
}

.bg-red {
  background-color: #a01d26;
}

.contact-details {
  background: linear-gradient(
    261deg,
    rgba(160, 29, 38, 0.2) -22.16%,
    rgba(160, 29, 38, 0) 97.22%
  );
}

.flyhigh {
  color: #a01d26;
}

.workwithus-bg {
  background: linear-gradient(
    261deg,
    rgba(160, 29, 38, 0.2) -22.16%,
    rgba(160, 29, 38, 0) 97.22%
  );
}

.acad-pos {
  position: absolute;
  top: 0;
  padding: 15% !important;
}

.acad-pos-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dicover-icon-img {
  width: 80px;
  height: 80px;
}

.discover-section hr {
  margin: 0.5rem 0 0.5rem;
}

.check-out-page .casestudy-para {
  height: 230px;
  font-size: 14px;
}

.heading-hightacad {
  height: 40px;
}

.thnk-modal-logo {
  width: 250px;
  height: auto;
}

.success-img {
  width: 100px;
  height: auto;
}

.case-para-home {
  height: 80px;
}

a {
  text-decoration: none;
}

.company-vibe-head {
  background-color: #a01d26;
  width: fit-content;
  padding: 5px 10px;
  margin: auto;
}

.op-img {
  width: 110px !important;
  height: 110px !important;
  margin: auto;
}

.philosophy-card-height {
  height: 250px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #a01d26;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.pos-footer-input {
  position: relative;
}

.img-top-team {
  margin-top: -35px;
}

.team-para-he {
  height: 40px;
}

.privyr_logo_image {
  height: auto;
  width: 100%;
  margin: 50px 0px 0px;
}

.affiliate-redirect-btn {
  color: #fff;
  background-color: #a01d26;
  border-color: #a01d26;
  border-radius: 6px;
  font-size: 18px;
  padding: 17px 90px;
  font-family: "OpenSans-Bold";
  margin: 0px 0px 90px !important;
}
.affiliate-redirect-btn:hover {
  color: #a01d26;
  background-color: #ffffff !important;
  border-color: #a01d26;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}
